/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const JobStatus = {
  READY: 'READY',
  FINISHED: 'FINISHED',
  ABORT: 'ABORT',
  ERROR: 'ERROR',
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
};

export function getJobIconByStatus(jobStatus) {
  if (jobStatus === JobStatus.READY) {
    return 'fa fa-circle-o';
  } else if (jobStatus === JobStatus.FINISHED) {
    return 'fa fa-circle';
  } else if (jobStatus === JobStatus.ABORT) {
    return 'fa fa-circle';
  } else if (jobStatus === JobStatus.ERROR) {
    return 'fa fa-circle';
  } else if (jobStatus === JobStatus.PENDING) {
    return 'fa fa-circle';
  } else if (jobStatus === JobStatus.RUNNING) {
    return 'fa fa-spinner';
  }
}

export function getJobColorByStatus(jobStatus) {
  if (jobStatus === JobStatus.READY) {
    return 'green';
  } else if (jobStatus === JobStatus.FINISHED) {
    return 'green';
  } else if (jobStatus === JobStatus.ABORT) {
    return 'orange';
  } else if (jobStatus === JobStatus.ERROR) {
    return 'red';
  } else if (jobStatus === JobStatus.PENDING) {
    return 'gray';
  } else if (jobStatus === JobStatus.RUNNING) {
    return 'blue';
  }
}
